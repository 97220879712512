export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21')
];

export const server_loads = [2];

export const dictionary = {
		"/": [3],
		"/(app)/accounts": [~4,[2]],
		"/(app)/contact": [5,[2]],
		"/(app)/d3": [6,[2]],
		"/(app)/dashboard": [~7,[2]],
		"/(app)/debts": [8,[2]],
		"/(app)/expenses": [~9,[2]],
		"/(app)/future": [10,[2]],
		"/(app)/loans": [11,[2]],
		"/login": [19],
		"/logout": [20],
		"/(app)/profile": [12,[2]],
		"/(app)/projects": [13,[2]],
		"/(app)/recurent": [~14,[2]],
		"/register": [21],
		"/(app)/repetitive": [15,[2]],
		"/(app)/revenue": [~16,[2]],
		"/(app)/settings": [17,[2]],
		"/(app)/things": [18,[2]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.js';